import dayjs from "dayjs";
import { get } from "lodash";
import { useState } from "react";
import Loading from "shared/components/Spin";
import { useLazyQuery, useQuery } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import { TRACER_TYPES } from "utils/constants";
import View from "./View";

const ProcessStats = () => {
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [processData, setProcessData] = useState(null);
  const [getProcessData] = useLazyQuery(graphql.queries.PROCESS_STATICS);
  const { data: processes, loading: loadingProcesses } = useQuery(
    graphql.queries.PROCESSES,
    {
      variables: {
        where: {},
      },
    }
  );

  const { loading } = useQuery(graphql.queries.TRACER, {
    variables: { where: { type: TRACER_TYPES.COMPARISON } },
    onCompleted: ({ tracer }) => {
      getProcessStats({
        id: get(tracer, "flow.process.id"),
        dateRange: [dayjs().startOf("M"), dayjs().endOf("M")],
      });
    },
  });

  const constructChart = (chartData) => {
    setProcessData({
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: chartData.data.map(({ name }) => name),
        },
      },
      series: [
        {
          name: chartData.process.name,
          data: chartData.data.map(({ count }) => count),
        },
      ],
    });
  };

  const getProcessStats = ({ id, dateRange }) => {
    setSelectedProcess(id);
    dateRange && setDateRange(dateRange);
    getProcessData({
      fetchPolicy: "no-cache",
      variables: {
        where: {
          id,
        },
        gt: { createdDate: dateRange ? dateRange[0].isValid() ? dateRange[0].format("YYYY-MM-DD") : dayjs().startOf('y').format("YYYY-MM-DD") : null },
        lt: { createdDate: dateRange ? dateRange[1].isValid() ? dateRange[1].format("YYYY-MM-DD") : dayjs().endOf('y').format("YYYY-MM-DD") : null },
      },
      onCompleted: ({ projectProcessStatusesStats }) => {
        constructChart(projectProcessStatusesStats);
      },
    });
  };

  if (loadingProcesses || loading || !processData) return <Loading />;
  return (
    <View
      dateRange={dateRange}
      getProcessStats={getProcessStats}
      selectedProcess={selectedProcess}
      processes={get(processes, "processes.data")}
      data={processData}
    />
  );
};

export default ProcessStats;
