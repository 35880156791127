import { Table } from "antd";
import cuid from "cuid";
import Projects from "entities/Projects";
import {
  first,
  get,
  intersection,
  isEmpty,
  last,
  filter as lodashFilter,
  sortBy,
} from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import { BsViewList } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { IoDuplicateOutline } from "react-icons/io5";
import {
  MdDeleteOutline,
  MdOpenInNew,
  MdOutlineEventBusy,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import Avatar from "shared/components/Avatar";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Filter from "shared/components/Filter";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Pagination from "shared/components/Pagination";
import Popover from "shared/components/Popover";
import Select from "shared/components/Select";
import Status from "shared/components/Status";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { USERS } from "utils/api/graphql/queries/users";
import {
  ACTIONS,
  DATE_TIME_FORMAT,
  PROCESS_TAGS as PROCESS_TAGS_STATUSES,
  USER_TYPES,
} from "utils/constants";
import ContactCard from "../../Clients/Card";
import Subscriptions from "./Subscriptions";

const LOCK_ICON = {
  [true]: <MdOutlineLock size={24} color={COLORS.C_GRAY} />,
  [false]: <MdOutlineLockOpen size={24} color={COLORS.C_GRAY} />,
};

const List = ({
  filter,
  onSearch,
  applyFilter,
  pagination,
  setPagination,
  duplicate,
  createProject,
  onDelete,
  onDetail,
  actions,
  exportSubscriptions,
  onAssign,
  tracer,
  isAssigning,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const FILTER = t("FILTER", { returnObjects: true });
  const LIST = t("LIST", { returnObjects: true });
  const PROCESS_TAGS = t("PROCESS_TAGS", { returnObjects: true });

  const [projectId, setProjectId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [project, setProject] = useState(null);
  const [search, setSearch] = useState({});
  const [open, setOpen] = useState(false);
  const showDrawerSubscription = (id) => {
    setProjectId(id);
  };
  const onCloseSubscription = () => {
    setProjectId(null);
  };

  const viewContactCard = (contactId, project) => {
    setContactId(contactId);
    setProject(project);
    setOpen(true);
  };

  const columns = [
    {
      width: 48,
      // responsive: ["sm"],
      dataIndex: "contact",
      render: (contact) => {
        return (
          <>
            <Avatar
              mode={"text"}
              size={"medium"}
              value={`${get(contact, "user.firstname", "")?.substring(
                0,
                1
              )}${get(contact, "user.lastname", "")?.substring(0, 1)}`}
            />
          </>
        );
      },
      fixed: true,
    },
    {
      title: LIST.CLIENT,
      dataIndex: "contact",
      render: (contact, project) => (
        <div className="d-flex">
          {get(contact, "user.firstname") && get(contact, "user.lastname") ? (
            <div
              className="name--client"
              onClick={() => viewContactCard(get(contact, "id"), project)}
            >
              {`${get(contact, "user.firstname", "")} ${get(
                contact,
                "user.lastname",
                ""
              )}`}
            </div>
          ) : (
            <span
              className="name--client"
              onClick={() => viewContactCard(get(contact, "id"), project)}
            >
              {/* <PiEyeLight color={COLORS.C_GRAY} size={24} /> */}
              {LIST.VIEW_CONTACT}
            </span>
          )}
        </div>
      ),
      fixed: true,
    },
    {
      title: LIST.STATUS,
      dataIndex: "statuses",
      render: (record, row) => {
        const currentStatus = get(
          last(
            sortBy(
              lodashFilter(
                record,
                ({ status }) =>
                  status.process.id === get(tracer, "flow.process.id")
              ),
              "createdDate"
            )
          ),
          "status"
        );
        const statusText =
          (row.expired && PROCESS_TAGS.EXPIRED) ||
          get(currentStatus, "name", PROCESS_TAGS.IN_PROGRESS);
        return (
          <Status
            type="custom"
            style={{
              color: get(currentStatus, "color"),
              background: get(currentStatus, "background"),
            }}
            text={statusText}
          />
        );
      },
    },
    {
      title: LIST.CREATION_DATE,
      dataIndex: "createdDate",
      render: (record) => moment(record).format(DATE_TIME_FORMAT),
    },
    {
      title: LIST.ADVISOR,
      dataIndex: "author",
      render: (record, row) => (
        <div className="select_200">
          <Select
            type="async"
            value={record?.id}
            node={{
              query: USERS,
              variables: {
                where: {
                  AND: [
                    { isActive: true },
                    {
                      OR: [
                        { meta: { type: USER_TYPES.INTERNAL_ADVISOR.type } },
                        { meta: { type: USER_TYPES.INTERNAL_SUPERVISOR.type } },
                        { meta: { type: USER_TYPES.BROKER_EMPLOYEE.type } },
                        { meta: { type: USER_TYPES.BROKER_ADMIN.type } },
                        {
                          meta: { type: USER_TYPES.BROKER_REPRESENTATIVE.type },
                        },
                      ],
                    },
                  ],
                },
              },
              accessors: {
                root: "users.data",
                label: {
                  separator: " ",
                  keys: ["firstname", "lastname"],
                },
              },
            }}
            placeholder={FILTER.BROKER}
            onChange={(value) =>
              onAssign({ projectId: row.id, authorId: value })
            }
            disabled={isAssigning[row?.id]}
            loading={isAssigning[row?.id]}
          />
        </div>
      ),
    },
    {
      width: 62,
      dataIndex: "locked",
      align: "right",
      render: (record, row) => (
        <div className="d-flex justify--end">
          {row.expired && (
            <div className="popover-wrapper">
              <Popover text={LIST.EXPIRED_POPOVER} />
              <MdOutlineEventBusy size={24} color={COLORS.C_DANGER} />
            </div>
          )}
          <div className="popover-wrapper">
            {record && (
              <Popover
                text={row.expired ? LIST.EXPIRED_LOCKED : LIST.LOCKED_POPOVER}
              />
            )}
            {LOCK_ICON[record]}
          </div>
        </div>
      ),
    },
    ...(actions[ACTIONS.UPDATE_SUBSCRIPTION] ||
    actions[ACTIONS.DELETE_PROJECT] ||
    actions[ACTIONS.DUPLICATE_PROJECT] ||
    actions[ACTIONS.UPDATE_PROJECT]
      ? [
          {
            title: LIST.ACTIONS,
            dataIndex: "id",
            align: "right",
            render: (id, project) =>
              get(project, "author") && (
                <div className="d-flex justify--end">
                  {!isEmpty(get(project, "subscriptions", [])) &&
                    intersection(
                      [
                        PROCESS_TAGS_STATUSES.EXPIRED,
                        PROCESS_TAGS_STATUSES.SIGNED,
                      ],
                      get(project, "statuses", []).map(
                        ({ status: { tag } }) => tag
                      )
                    ).length === 0 &&
                    actions[ACTIONS.UPDATE_SUBSCRIPTION] &&
                    !project?.expired && (
                      <Button
                        type="primary--light"
                        icon={<BsViewList />}
                        onClick={() => showDrawerSubscription(id)}
                      >
                        {LIST.RESUME_SUBSCRIPTION}
                      </Button>
                    )}

                  {actions[ACTIONS.DUPLICATE_PROJECT] && (
                    <IoDuplicateOutline
                      size={24}
                      onClick={() => {
                        duplicate({
                          variables: {
                            where: { id },
                            data: {
                              visible: true,
                              statuses: [
                                {
                                  id: cuid(),
                                  status: {
                                    id: get(
                                      first(
                                        sortBy(
                                          get(
                                            tracer,
                                            "flow.process.processStatuses"
                                          ),
                                          "order"
                                        )
                                      ),
                                      "id"
                                    ),
                                  },
                                },
                              ],
                            },
                          },
                        });
                      }}
                    />
                  )}
                  {actions[ACTIONS.DELETE_PROJECT] && (
                    <MdDeleteOutline
                      color={COLORS.C_DANGER}
                      size={24}
                      onClick={() => onDelete(id)}
                    />
                  )}
                  {actions[ACTIONS.PROJECT] &&
                    actions[ACTIONS.UPDATE_PROJECT] && (
                      <MdOpenInNew
                        color={COLORS.C_PRIMARY}
                        size={24}
                        onClick={() => onDetail(project)}
                      />
                    )}
                </div>
              ),
          },
        ]
      : []),
  ];
  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        actions={[
          ...(actions[ACTIONS.ADD_PROJECT]
            ? [
                {
                  icon: <BiPlus size={20} />,
                  text: LIST.HEADER.CREATE.NORMAL,
                  onClick: createProject,
                },
              ]
            : []),
          ...(actions[ACTIONS.EXPORT_SUBSCRIPTIONS]
            ? [
                {
                  icon: <TbFileExport size={20} />,
                  text: LIST.HEADER.EXPORT_SUBSCRIPTIONS,
                  onClick: exportSubscriptions,
                },
              ]
            : []),
        ]}
      />
      <Card type="simple">
        <div className="filter__area">
          <div className="search--bar">
            <Input
              placeholder={LIST.FILTERS.CLIENT_LAST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, clientLastName: value }))
              }
            />
            <Input
              placeholder={LIST.FILTERS.CLIENT_FIST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, clientFirstName: value }))
              }
            />
            <Input
              placeholder={LIST.FILTERS.ADVISOR_LAST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, advisorLastName: value }))
              }
            />
            <Input
              placeholder={LIST.FILTERS.ADVISOR_FIST_NAME}
              onChange={({ target: { value } }) =>
                setSearch((prev) => ({ ...prev, advisorFirstName: value }))
              }
            />
            <Button
              type="secondary"
              onClick={() => onSearch(search)}
              icon={<FaSearch size={14} />}
              size={"full--w"}
            >
              {LIST.FILTERS.FILTER}
            </Button>
          </div>
          <Filter
            translate={FILTER.PROCESS_TAG}
            items={Object.keys(PROCESS_TAGS)}
            setFilter={(items) => {
              applyFilter({
                expired: !!items.find(
                  (item) => item === PROCESS_TAGS_STATUSES.EXPIRED
                ),
                tags: items.filter(
                  (item) => item !== PROCESS_TAGS_STATUSES.EXPIRED
                ),
              });
            }}
          />
        </div>
      </Card>
      {contactId && (
        <ContactCard
          contactId={contactId}
          project={project}
          open={open}
          tracer={tracer}
          setOpen={setOpen}
        />
      )}
      <div className="m-bottom-24" />
      <Projects
        View={({ count, data, setValues, type, skip }) => (
          <Card type={"simple"}>
            <Table
              dataSource={data}
              columns={columns}
              className="table__wrapper"
              rowKey={"id"}
              pagination={false}
              scroll={{ x: true }}
            />
            <Pagination
              footprint={type}
              count={count}
              setValues={setValues}
              skip={skip || 0}
            />
          </Card>
        )}
        setValues={setPagination}
        skip={pagination.skip}
        filter={{ ...filter, ...pagination }}
        fetchPolicy="network-only"
      />
      {projectId && (
        <Subscriptions onClose={onCloseSubscription} project={projectId} />
      )}
    </>
  );
};

export default List;
